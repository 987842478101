.rodape{
	@include get-proppertys($rodape);

	.fa{
		transition:transform 0.5s ease-out;
	}

	.link-chev:hover{
		.fa.fa-chevron-right.fnt-14{
			transform:translateX(5px);
		}
	}

	a:hover,
	a:focus{
		color:#FFFFFF;

	}

	.title-section{
		font-size:20px;
		font-family: 'poppinsbold';
		margin-top:0;
		$TitleSection: map-get($rodape, 'TitleSection');
		@include get-proppertys($TitleSection);
	}

	.cor-golden-footer{
		color:$ColorGoldenFooter;
	}

	#menu-footer{

		li{
			margin-bottom:10px;
		}

		li.active a,
		li:hover a{
			$ColorActiveMenuRodape: map-get($rodape, 'ColorActiveMenuRodape');
			@include get-proppertys($ColorActiveMenuRodape);
		}
	}

	.copyright{
		$Copyright: map-get($rodape, 'Copyright');
		@include get-proppertys($Copyright);
		padding-top:25px;

		.container{
			padding-top:25px;	
			border-color:#716f8c;
			border-top-width:1px;
			border-top-style:solid;
		}
	}

	.irtopo{
		position:fixed;
		bottom:10px;
		right:10px;
		border:none;
		background:$theme-color;
		opacity:0.4;
		color:#FFFFFF;
		width:2.5em;
		height:2.5em;
		line-height:2.5em;
		text-align:center;
		font-size:26px;
		border-radius:100%;
		outline:none;
		transition:opacity 0.5s ease-out;
		z-index:100;

		&:hover,
		&:focus{
			opacity:1;
		}
	}
}
.btn__whatsapp{
	position: fixed;
    width: 60px;
    height: 60px;
    border-radius: 50%;;
    background-color: #25D366;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    line-height: 40px;
    z-index: 800;

	&:hover{
		text-decoration: none;
	}

	&:after{
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #25D366;
        animation: pulsar 2s linear infinite;
        z-index: -1;
    }

	&,
	.btn__whatsapp__wrapper{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.5em;//  40px * 1.5 = 60px
		height: 1.5em;// 40px * 1.5 = 60px
		border-radius: 50%
	}

	.btn__whatsapp__wrapper{
		overflow: hidden;
	}

	svg{
		// $dropShadow: drop-shadow(1px 1px #01883c);

		// @for $i from 2 through 60 {
		// 	$dropShadow: #{$dropShadow} drop-shadow(#{$i}px #{$i}px #01883c);
		// }

		width: 1em;
		height: 1em;
		fill: #FFF;
		// filter: $dropShadow;
	}
}
@keyframes pulsar{
    0%{
        opacity: 0.7;
        transform: scale(1);
    }
    100%{
        opacity: 0;
        transform: scale(2);
    }
}
.politica__btn{
	display: flex;
	justify-content: flex-end;
	padding-top: 30px;

	.btn{
		font-family: 'poppinsbold';
	}
}
.msg__cookies{
	background: #FFF;
	position: fixed;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	padding: 15px;
	border-radius: 15px;
	box-shadow: 0 0 10px rgba(#000,.8);
	width: 100%;
	z-index: 999999999;
	border: 1px solid rgba(#000,.2);

  &.d-none{
    display: none;
  }

	a{
		color: #141237;
		text-decoration: underline;
	}

	.btn{
		background-color: #141237;
		font-weight: bold;
    	color: #fff;

			&:hover{
			background-color: darken(#141237, 10%);
			color: #fff;
    	}
	}

	@media (max-width: 767px){
		max-width: calc(100% - 30px);
		font-size: 12px;
		text-align: center;

		a{
			display: inline-block;
		}

		.content{
			margin-bottom: 10px;
		}
	}

  @media (min-width: 767px){
		display: grid;
		grid-template-columns: 1fr min-content;
		grid-column-gap: 10px;
		align-items: center;

		.btn{
			white-space: nowrap;
		}
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		max-width: 768px;
	}

  @media (min-width: 992px) and (max-width: 1199.98px) {
		max-width: 1024px;
	}

  @media (min-width: 1200px) {
		max-width: 1280px;
	}
}