.galeria-h{
	margin-bottom:30px;

	h1.nome{
		margin-top:0;
		font-size:20px;
	}
}

.galeria-v{
	width:220px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:30px;

	.foto{
		height:220px;
		overflow:hidden;
		display:flex;

		@include prefixos(justify-content,center);
		@include prefixos(align-items,center);
	}

	.nome{
		margin-top:7px;
		font-weight:bold;
		text-align:center;
		@include resumo(18px,1.3,2);
	}
}