$ColorPurple:#313253;

$ColorDanger:#922628;

$ColorGolden:#ffd202;

$BgCinza:#ebebeb;

// BOTAO THEME OF SITE
$BotaoTheme:(
	color:#FFFFFF,
	background:$ColorGolden,

	Hover:(
		color:#FFFFFF,
		background:$ColorPurple,
	),
);

$Destaques:(

	background:$BgCinza,
	
	CardTitle:(
		color:$ColorPurple,
	),

	CardText:(
		color:#999999,
	),

);

$SobreNos:(

);

$SegundoVideo:(
	background:$BgCinza,
);

$Noticias:(

	TitleSection:(
		color:$ColorDanger,
	),

	CardNoticies:(

		TitleNews:(
			color:$ColorPurple,
		),

		TextNews:(
			color:#888888,
		),

	),
	
);

$Parceiros:(
	background:$BgCinza,

	TitleSection:(
		color:$ColorDanger,
	),
);

$OwlCarousel:(

	DotAtivo:(
		background:$ColorPurple,
	),

	Dot:(
		background:$BgCinza,
		border-color:#8e8e9f,

		DotBefore:(
			border-color:#3a3b5a,
		),
	),

);