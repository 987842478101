@media (min-width:$min-lg) and (max-width:$max-lg){

	.mobile-controls{
		display:none;
	}

	.container{
		width:960px;
	}

	.row.flex,
	ul.row{
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
	}

	.topo{

		.nav-bar{

			.nav-content{

				.flex-content{

					width:960px;
					display:flex;
					flex-wrap:wrap;
					align-items:center;
					justify-content:space-between;
					padding:0 15px;

					#form-busca{
						flex-grow:0;
					}

					& > *{
						padding:20px 0;
					}

					.contacts-boxes{
						display:flex;
						$ContactBoxes: map-get($topo, 'ContactBoxes');
						@include get-proppertys($ContactBoxes);
					}

					.contact-box + .contact-box{
						margin-left:25px;
					}

					#menu{
						display:flex;
						justify-content:space-between;
						position:relative;
						width:100%;
						align-items:center;
						flex-wrap:wrap;

						.flex{
							display:flex;
							width:100%;
							justify-content:space-between;

							a{
								display:inline-block;
								padding:23px 0 23px;
							}

						}

						.redes-sociais{
							padding:10px 0 19px;
							text-align:center;
							width:100%;
						}

						&:before,
						&:after{
							content:'';
							top:0;
							position:absolute;
							background:$theme-color;
							width:100%;
							height:100%;
						}

						&:before{
							right:100%;
						}

						&:after{
							left:100%;
						}
					}

				}
			}
			
		}
		
	}

	.rodape{
		.flex{
			display:flex;
			justify-content:space-between;
			align-items:center;
		}
	}
	
}