$ColorGoldenFooter:#dd9d18;

$rodape:(
	background:#141237,
	color:#7d7b9e,

	TitleSection:(
		color:#FFFFFF,
	),

	ColorActiveMenuRodape:(
		color:#FFFFFF,
	),

	Copyright:(
		color:#FFFFFF,
	),
);