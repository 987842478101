a{
	color:inherit;
	text-decoration:inherit;
	transition:color 0.5s ease-out;

	&:hover{
		color:$theme-color;
	}
}

a:hover{
	text-decoration:inherit;
}

.list-unstyled{
	margin-bottom:0;
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

body{
	font-family:'montserratlight', helvetica , arial, sans-serif;
	color:#666666;
	line-height:1.45;
}

.btn{
	font-size:inherit;

	&.btn-theme{
		@include get-proppertys($BotaoTheme);
		position:relative;
		z-index:5;
		min-width:161px;
		border-radius:30px;
		padding:17px 20px;
		text-align:left;
		min-height:55px;
		transition:background 0.5s ease-out;
		margin-left:33px;

		&:hover{
			$Hover: map-get($BotaoTheme, 'Hover');
			@include get-proppertys($Hover);
			

			&:before{
				background:$ColorGolden;
			}

			&:after{
				background:$ColorPurple;
			}
		}

		&:before,
		&:after{
			content:'';
			display:block;
			width:100%;
			height:55px;
			position:absolute;
			top:-1px;
			transition:background 0.5s ease-out;
			border-radius:30px;
		}

		&:before{
			background:$ColorPurple;
			left:calc(0% - 33px);
			z-index:-5;
		}

		&:after{
		    background:$ColorGolden;
		    left:calc(0% - 23px);
		    z-index:-2;
		}
	}

	&:hover{
		color:inherit;
	}
}

// COLOR OF FONTS
@include text-emphasis-variant('.text-white', #FFF);

@include text-emphasis-variant('.text-danger',#922628);

// FONTS FAMILYS

.mst-blk{
	font-family: 'montserratblack';
}

.mst-bld{
	font-family: 'montserratbold';
}

.mst-ex-bld{
	font-family: 'montserratextrabold';
}

.mst-lgt{
	font-family: 'montserratlight';
}

.ppns-bld{
	font-family: 'poppinsbold';
}

.ppns-semi-bld{
	font-family: 'poppinssemibold';
}

// FONTS SIZES

.fnt-11{
	font-size:11px;
}

.fnt-12{
	font-size:12px;
}

.fnt-13{
	font-size:13px;
}

.fnt-14{
	font-size:14px;
}

.icon{
	background-image:url(#{$imgs}/sprites.png);
	background-repeat: no-repeat;
	display:inline-block;
}

$sprites: (
	'icon-gv8': 66px 21px 0 0
);

@each $icon, $dados in $sprites{

	.#{$icon}{
		width: nth($dados, 1);
		height: nth($dados, 2);
		background-position: #{nth($dados,3)} #{nth($dados,4)};
	}
}
