@media (min-width:$min-xl){

	.mobile-controls{
		display:none;
	}

	.container{
		width:1110px;
	}

	.row.flex,
	ul.row{
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
	}

	.topo{

		.nav-bar{

			.nav-content{
				.flex-content{
					width:1110px;
					display:flex;
					flex-wrap:wrap;
					align-items:center;
					justify-content:space-between;
					padding:0 15px;

					.contacts-boxes{
						display:flex;
						$ContactBoxes: map-get($topo, 'ContactBoxes');
						@include get-proppertys($ContactBoxes);
						padding-left:38px;
					}

					.contact-box + .contact-box{
						margin-left:25px;
					}

					#menu{
						display:flex;
						justify-content:space-between;
						position:relative;
						width:100%;
						align-items:center;
						flex-wrap:wrap;


						.flex{
							display:flex;
							justify-content:space-between;
							width:(782 * 100) / 1110 * 1%;
							float:left;

							&:before,
							&:after{
								content:'';
								display:table;
								clear:both;
							}


							a{
								display:inline-block;
								padding:23px 0 23px;
							}

						}

						.redes-sociais{
							float:left;
							padding:20px 0 19px;
							text-align:right;
							width:(328 * 100) / 1110 * 1%;

							&:before,
							&:after{
								content:'';
								display:table;
								clear:both;
							}
							
						}

						&:before,
						&:after{
							content:'';
							top:0;
							position:absolute;
							background:$theme-color;
							width:100%;
							height:100%;
						}

						&:before{
							right:100%;
						}

						&:after{
							left:100%;
						}
					}

				}
			}
			
		}
		
	}

	.rodape{
		.flex{
			display:flex;
			justify-content:space-between;
			align-items:center;
		}
	}
}