// what the internals have in comum

.sidebar .sidebar-heading{
	background:$theme-color;
}

.sidebar ul .active a, .sidebar ul a:hover{
	background:lighten($theme-color, 10%);
	color:#FFFFFF;
}

.sidebar .nome a:hover{
	background:$theme-color;
	color:#FFFFFF;
}

.paginacao .active, .paginacao :hover{
	background:$theme-color;
}

#carousel-2 .controls{
	background:$theme-color;

	&:hover,
	&:focus{
		background:darken($theme-color, 10%);
	}
}