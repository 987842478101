.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}


.noround{
    border-radius:0;
}

.img-responsive{
    display: inline-block;
}


.anima{
    opacity:0;
    &.animated{
        opacity:1;
    }
}

//XS
$max-xs:575.98px;

//SM
$min-sm:576px;
$max-sm:767.98px;

// MD
$min-md:768px;
$max-md:991.98px;

// LG
$min-lg:992px;
$max-lg:1199.98px;

// XL
$min-xl:1200px;

.video{
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    cursor:pointer;

    .description{
        background-repeat:repeat-x;
    }
}

.internas img{
    @extend .img-responsive;
}


.wrapper{
    overflow:hidden;
}


.parallax{
    background-size:cover;
    background-attachment:fixed;
}

.title-section,
.subtitle-section{
    margin-top:0;
}

.owl-carousel{
    .item{
        margin-left:auto;
        margin-right:auto;
        text-align:center;

        img{
            margin-left:auto;
            margin-right:auto;
        }
    }
    
    .owl-dots{
        text-align:center;
        margin-left:auto;
        margin-right:auto;

        .owl-dot{
            display:inline-block;
            margin:0 7px;
            cursor:pointer;

            &.active{
                span{
                    $DotAtivo: map-get($OwlCarousel, 'DotAtivo');
                    @include get-proppertys($DotAtivo);
                }
            }

            &:first-child{
                margin-left:0;
            }

            &:last-child{
                margin-right:0;
            }

            span{
                width:16px;
                height:16px;
                display:inline-block;
                margin-bottom:2px;
                border-radius:100%;
                border-width:3px;
                $Dot: map-get($OwlCarousel, 'Dot');
                @include get-proppertys($Dot);
                border-style:solid;
                position: relative;

                &:before{
                    content:'';
                    display:block;
                    $DotBefore: map-get($Dot, 'DotBefore');
                    @include get-proppertys($DotBefore);
                    border-width:1px;
                    border-style:solid;
                    width:100%;
                    border-radius:100%;
                    height:100%;
                    top:0;
                    left:0;
                }
            }
        }

    }
}

.owl-carousel{

    img{
        display:inline-block !important;
    }
    
}