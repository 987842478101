$theme-color:#141237;

$topo:(

	MobileControlsBg:(
		background:#FFFFFF,
	),

	FaContactTopo:(
		background:$theme-color,
		color:#FFFFFF,
	),

	ContactBoxes:(
		color:#bbb7b7,
	),

	InputGroupBorderColor:(
		border-color:#e1e1e1,
	),

	BgInputGroup:(
		background:#FFFFFF,
	),

	FormControl:(
		color:#bbb7b7,
	),

	BgBotaoPesquisa:(
		background:#a63335,
		border-color:#a63335,
	),

	Menu:(
		background:$theme-color,
		color:#FFFFFF,
	),

	MenuResponsivo:(
		background:#FFFFFF,
		color:$theme-color,
	),

	LiActive:(
		color:#ffd202,
	),

	ToggleMenu:(
		color:$theme-color,
		background:none,

		HoverFocus:(
			color:#FFFFFF,
			background:$theme-color,
		),
	),


);