.mapa-google{
	position:relative;

	iframe{
		width:100%;
		height:100%;
		position:absolute;
	}
}

#mapa-39{
	padding-bottom:100%;
}

#mapa-42{
	padding-bottom:50%;
	margin-bottom:20px;
}

#mapa-45{
	padding-bottom:50%;
	margin-top:20px;
}

#mapa-104{
	padding-bottom:50%;
}

#mapa-107{
	padding-bottom:50%;
}

#mapa-110{
	padding-bottom:100%;
}