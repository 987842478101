.destaques{
	@include get-proppertys($Destaques);

	.flex-legal{

		@media(min-width:$min-md){
			display:flex;
			flex-wrap:wrap;
		}

	}

}

.sobre-nos{
	@include get-proppertys($SobreNos);

	.title-section{
		font-size:20px;
		color:$ColorPurple;
		margin-top:0;
	}

	.big-title{
		font-size:61px;
	}

}

.segundo-video{
	@include get-proppertys($SegundoVideo);
}

.noticias{

	.subtitle-section{
		font-size:14px;
	}

	.title-section{
		font-family: 'montserratbold';
		font-size:30px;
		$TitleSection: map-get($Noticias, 'TitleSection');
		@include get-proppertys($TitleSection);
	}

	.card-news{
		$CardNoticies: map-get($Noticias, 'CardNoticies');
		@include get-proppertys($CardNoticies);

		.title-news{
			margin-top:0;
			margin-bottom:15px;
			font-size:17px;
			font-family: 'montserratbold';
		}

		.title-news{
			$TitleNews: map-get($CardNoticies, 'TitleNews');
			@include get-proppertys($TitleNews);
		}

		.text{
			$TextNews: map-get($CardNoticies, 'TextNews');
			@include get-proppertys($TextNews);
		}
	}

}

.parceiros{
	@include get-proppertys($Parceiros);

	.subtitle-section{
		font-size:14px;
	}

	.title-section{
		font-family: 'montserratbold';
		font-size:30px;
		$TitleSection: map-get($Parceiros, 'TitleSection');
		@include get-proppertys($TitleSection);
	}

}