.topo{
	@include get-proppertys($topo);

	.nav-bar{

		.mobile-controls{

			#toggleMenu{
				$ToggleMenu: map-get($topo, 'ToggleMenu');
				@include get-proppertys($ToggleMenu);
				border:none;
				font-size:25px;
				border:2px solid $theme-color;
				border-radius:5px;
				transition:color,background 0.5s ease-out;
				outline:none;

				&:hover{
					$HoverFocus: map-get($ToggleMenu, 'HoverFocus');
					@include get-proppertys($HoverFocus);
				}

				&:focus{
					$HoverFocus: map-get($ToggleMenu, 'HoverFocus');
					@include get-proppertys($HoverFocus);
				}
			}
		}

		.nav-content{

			.flex-content {
				margin:0 auto;

				& > * {
					padding:35px 0 35px 0;
				}

				.contacts-boxes{
					flex-grow:1;
					justify-content:center;

					.contact-box{

						.fa-default-circle{
							text-align:center;
							border-radius:100%;
							$FaContactTopo: map-get($topo, 'FaContactTopo');
							@include get-proppertys($FaContactTopo);
						}

						.fa.fa-envelope{
							width:2.7em;
							height:2.7em;
							line-height:2.7em;
							font-size:16px;
							@extend .fa-default-circle;
						}

						.fa.fa-whatsapp{
							width:1.97em;
							height:1.97em;
							line-height:1.97em;
							font-size:22px;
							@extend .fa-default-circle;
						}
					}
				}


				#form-busca{
					flex-grow:1;
					transition:all 0.5s linear;

					.input-group{
						display:flex;
						align-items:stretch;
						flex-wrap:wrap;
						width:100%;
						border-radius:50px;
						overflow:hidden;
						padding:1px 5px 2px 1px;
						border:1px solid;
						$BgInputGroup: map-get($topo, 'BgInputGroup');
						@include get-proppertys($BgInputGroup);
						$InputGroupBorderColor: map-get($topo, 'InputGroupBorderColor');
						@include get-proppertys($InputGroupBorderColor);

						.form-control{
							$FormControl: map-get($topo, 'FormControl');
							@include get-proppertys($FormControl);
							flex:1 1 auto;
						    width:1%;
						    margin-bottom:0;
						    height:inherit;
						    padding-left:20px;
						    border:none;
							box-shadow:none;
							font-size:13px;
							font-family: 'montserratblack';

							@include placeholdIt{
								$FormControl: map-get($topo, 'FormControl');
								@include get-proppertys($FormControl);
								font-size:13px;
								font-family: 'montserratblack';
							}
						}

						.input-group-prepend{
							display:flex;
							margin-right:-1px;
							$BgInputGroup: map-get($topo, 'BgInputGroup');
							@include get-proppertys($BgInputGroup);

							.btn-danger{
								border-radius:100%;
								$BgBotaoPesquisa: map-get($topo, 'BgBotaoPesquisa');
								@include get-proppertys($BgBotaoPesquisa);
								outline:none;
								color:#FFFFFF;
							}

						}
					}
				}

				#menu{
					padding:0;
					$Menu: map-get($topo, 'Menu');
					@include get-proppertys($Menu);

					li.active a,
					li:hover a{
						$LiActive: map-get($topo, 'LiActive');
						@include get-proppertys($LiActive);
					}
				}

				.redes-sociais{
					font-size:19px;

					a{
						padding:0;
						margin:0 14px;
						transition:transform, color 0.5s ease-out;

						&:hover{
							$LiActive: map-get($topo, 'LiActive');
							@include get-proppertys($LiActive);
							animation:rubberBand 0.5s linear;
						}

						&:last-child{
							margin-right:0;
						}

						&:first-child{
							margin-left:0;
						}
					}
				}
			}

			
		}
	}

}