.link-util{
	padding-bottom:15px;
	margin-bottom:15px;
	border-bottom:1px solid #ccc;

	h1{
		margin-top:0;
		font-size:18px;
	}

	h2{
		font-size:16px;
	}

	a{
		text-decoration:underline;
	}
}

.links-flex{
	display:flex;
	@include prefixos(flex-wrap,wrap);
}