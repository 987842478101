@media (min-width:$min-sm) and (max-width:$max-sm) {

	.container{
		width:540px;
	}

	.row.flex,
	ul.row{
		margin:0 auto;
		text-align:center;
	}

	
}