#destaques-carousel,
#noticias-carousel{

	.owl-stage{

		@media(min-width:$min-md){
			display:flex;
			flex-wrap:wrap;

			.owl-item{
				display:flex;
				flex-wrap:wrap;

				.card-destaques,
				.card-news{
					display:flex;
					flex-wrap:wrap;
					flex-direction:column;
					align-items:center;
				}

				.card-title{
					flex-grow:1;
				}

				.card-news{
					align-items:flex-start;
				}

			}

		}
		

	}

}

.card-destaques{

	.card-title{
		$CardTitle: map-get($Destaques, 'CardTitle');
		@include get-proppertys($CardTitle);
		font-family: 'montserratbold';
		font-size:30px;
		line-height:1.1;
	}

}