@media (max-width:$max-sm){

	.text-center-mobile{
		text-align:center;
	}

	body{
		padding-top:72px;
	}

	.row.flex,
	ul.row{
		margin:0 auto;
		text-align:center;
	}

	.topo{

		.nav-bar{

			.mobile-controls{
				display:flex;
				justify-content:space-between;
				padding-bottom:10px;
				padding-top:10px;
				position:fixed;
				box-shadow:0 0 8px rgba(0,0,0,0.4);
				top:0;
				left:0;
				$MobileControlsBg: map-get($topo, 'MobileControlsBg');
				@include get-proppertys($MobileControlsBg);
				width:100%;
				z-index:1000;

			}

			&.active{

				.bg-menu{
					display:block;
				}

				.nav-content{
					left:0;
				}
			}

			.bg-menu{
				position:fixed;
				display:none;
				top:0;
				left:0;
				width:100%;
				height:100%;
				background:rgba(#333333, 0.5);
				z-index:999;
			}

			.nav-content{
				position:fixed;
				top:0;
				left:-300px;
				overflow:auto;
				transition:all 0.6s ease-out;
				z-index:1000;
				$MenuResponsivo: map-get($topo, 'MenuResponsivo');
				@include get-proppertys($MenuResponsivo);
				width:250px;
				height:100%;

				.flex-content{

					& > * {
						padding:15px 0 30px 0;
					}

					.brand-desktop{
						padding-left:15px;
						padding-right:15px;
					}

					#form-busca{
						padding-left:10px;
						padding-right:10px;
					}

					#menu{
						$MenuResponsivo: map-get($topo, 'MenuResponsivo');
						@include get-proppertys($MenuResponsivo);

						li{
							display:block;

							&.active{
								$LiActive: map-get($topo, 'LiActive');
								@include get-proppertys($LiActive);
							}

							a{
								display:block;
								padding:10px 20px;
							}
						}
					}

					.contacts-boxes{
						display:none;
					}

					.redes-sociais{
						text-align:center;
						padding-top:20px;
					}
				}
			}
		}
	}

	.rodape{
		text-align:center;

		.irtopo{
			display:none;
		}
	}

	
}