@font-face {
    font-family: 'montserratblack';
    src: url('#{$fonts}/Montserrat-Black/montserrat-black-webfont.eot');
    src: url('#{$fonts}/Montserrat-Black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Montserrat-Black/montserrat-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/Montserrat-Black/montserrat-black-webfont.woff') format('woff'),
         url('#{$fonts}/Montserrat-Black/montserrat-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/Montserrat-Black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'montserratbold';
    src: url('#{$fonts}/Montserrat-Bold/montserrat-bold-webfont.eot');
    src: url('#{$fonts}/Montserrat-Bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Montserrat-Bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/Montserrat-Bold/montserrat-bold-webfont.woff') format('woff'),
         url('#{$fonts}/Montserrat-Bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/Montserrat-Bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'montserratextrabold';
    src: url('#{$fonts}/Montserrat-ExtraBold/montserrat-extrabold-webfont.eot');
    src: url('#{$fonts}/Montserrat-ExtraBold/montserrat-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Montserrat-ExtraBold/montserrat-extrabold-webfont.woff2') format('woff2'),
         url('#{$fonts}/Montserrat-ExtraBold/montserrat-extrabold-webfont.woff') format('woff'),
         url('#{$fonts}/Montserrat-ExtraBold/montserrat-extrabold-webfont.ttf') format('truetype'),
         url('#{$fonts}/Montserrat-ExtraBold/montserrat-extrabold-webfont.svg#montserratextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'montserratlight';
    src: url('#{$fonts}/Montserrat-Light/montserrat-light-webfont.eot');
    src: url('#{$fonts}/Montserrat-Light/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Montserrat-Light/montserrat-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/Montserrat-Light/montserrat-light-webfont.woff') format('woff'),
         url('#{$fonts}/Montserrat-Light/montserrat-light-webfont.ttf') format('truetype'),
         url('#{$fonts}/Montserrat-Light/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsbold';
    src: url('#{$fonts}/Poppins-Bold/poppins-webfont.eot');
    src: url('#{$fonts}/Poppins-Bold/poppins-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Poppins-Bold/poppins-webfont.woff2') format('woff2'),
         url('#{$fonts}/Poppins-Bold/poppins-webfont.woff') format('woff'),
         url('#{$fonts}/Poppins-Bold/poppins-webfont.ttf') format('truetype'),
         url('#{$fonts}/Poppins-Bold/poppins-webfont.svg#poppinsbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinssemibold';
    src: url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.eot');
    src: url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.woff2') format('woff2'),
         url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.woff') format('woff'),
         url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.ttf') format('truetype'),
         url('#{$fonts}/Poppins-SemiBold/poppins-semibold-webfont.svg#poppinssemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}